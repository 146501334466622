<template>
  <div>
    <template v-if="profile">
      <div class="match-count">
        Matches {{ matchCount }} count{{ matchCount === 1 ? 'y' : 'ies' }}
      </div>
      <FilterField label="Population" :filter="profile.populationFilter"/>
      <!-- <FilterField label="Demographics" :filter="filters.population"
        type="percent" race="single" /> -->
      <FilterField label="Registered Voters" :filter="profile.registeredVoterFilter" />
      <FilterField label="Eligible Voters" :filter="profile.eligibleVoterFilter" />
      <FilterField label="Election Turnout" :filter="profile.turnoutFilter" />
      <FilterField label="Election Results" :filter="profile.electionResultsFilter" />
    </template>
  </div>
</template>

<script>
import FilterField from './FilterField.vue';
export default {
  components: {FilterField},
  props: ['profile'],
  data() {
    return { };
  },
  computed: {
    matchCount: function() {
      var sum = 0;
      this.model.counties.forEach(county => {
        if (this.profile.isMatch(county.model)) sum++;
      });
      return sum;
    }
  }
}
</script>

<style scoped>
.match-count {
  padding: 4px 16px;
  font-weight: 500;
}
</style>
