<template>
  <div class="container">
    <div class="row">
      <div class="col s12 m10 offset-m1 l8 offset-l2 center">
        <div class="title-1">Blue Peach</div>
        <div class="logo-bar">
          <img src="/favicon.png" />
        </div>
        <div class="title-2">Analytics</div>
        <div class="row">
          <div class="col s12">
            <p>
              Only <strong>{{ moment('20201207', 'YYYYMMDD').diff(moment(), 'days') }}
              days</strong> remain before the voter registration deadline for the Georgia
              runoff election.
            </p>
            <p>
              Our software empowers organizers to focus their resources where they
              will have the greatest impact. Organizers can use our tool to define
              profiles of would-be Democractic voters, and quickly identify communities
              projected to match these profiles. Get started below!
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col s6 big-link">
            <router-link :to="{ name: 'list', params: {} }">
              <i class="material-icons medium">list</i><br />
              County List
            </router-link>
          </div>
          <div class="col s6 big-link">
            <router-link :to="{ name: 'map', params: {} }">
              <i class="material-icons medium">map</i><br />
              County Map
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeRemaining: 0
    };
  }
}
</script>

<style scoped>
.title-1, .title-2 {
  font-weight: 900;
  letter-spacing: 8px;
  font-size: 40px;
  text-transform: uppercase;
  font-family: 'Raleway';
}
.title-1 {
  color: #215DBF;
  margin-top: 48px;
}
.title-2 {
  color: #0D2752;
  margin-bottom: 24px;
}
.logo-bar {
  border-top: 2px solid #215DBF;
  margin-top: 22px;
  margin-bottom: -4px;
}
.logo-bar > img {
  margin-top: -22px;
  height: 42px;
}
p {
  font-family: 'Avenir';
  font-weight: 200;
  font-size: 18px;
}
p strong {
  font-weight: 900;
}
.big-link a {
  color: #215DBF;
  font-family: 'Raleway';
  font-weight: 900;
  text-transform: uppercase;
}
</style>
