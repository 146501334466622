<template>
  <div>
    <div class="clearfix stack-title">
      <div v-if="!hideTotal" class="left">
        {{ formatNumber(total) }}
      </div>
      <div v-if="percent != null" class="right">
        {{ formatPercent(percent) }}
      </div>
      <div class="responsive-text">
        <template v-if="!hideTotal"> </template>{{ label || 'Total' }}
      </div>
    </div>
    <BarStack :segments="segments" />
    <table>
      <tbody>
        <tr v-for="segment in segments" :style="{color:segment.color}">
          <td align="right">
            {{ formatNumber(Math.round(segment.count)) }}
          </td>
          <td v-if="showSubBars && segment.percent && !segment.hidePercent" width="100%">
            <BarStack :segments="[{color:segment.color,count:segment.percent},{color:`${segment.color}22`,count:1-segment.percent}]" />
          </td>
          <td v-else width="100%" class="responsive-text">
            {{ segment.label }}
          </td>
          <td align="right">
            {{ segment.hidePercent ? '' : formatPercent(segment.percent == null ? (segment.count / total) : segment.percent) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BarStack from './BarStack.vue';
export default {
  components: {BarStack},
  props: ['total', 'percent', 'label', 'segments', 'hideTotal', 'showSubBars'],
  data() {
    return {};
  },
}
</script>

<style scoped>
table {
  width: auto;
  margin: 0 auto;
}
table tr {
  border: none;
}
table tr.strong {
  font-weight: bold;
}
table td {
  padding: 0px 4px;
  text-align: left;
}
table td[align=right] {
  text-align: right;
}
</style>
