var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.profile
        ? [
            _c("div", { staticClass: "match-count" }, [
              _vm._v(
                "\n      Matches " +
                  _vm._s(_vm.matchCount) +
                  " count" +
                  _vm._s(_vm.matchCount === 1 ? "y" : "ies") +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _c("FilterField", {
              attrs: {
                label: "Population",
                filter: _vm.profile.populationFilter
              }
            }),
            _vm._v(" "),
            _c("FilterField", {
              attrs: {
                label: "Registered Voters",
                filter: _vm.profile.registeredVoterFilter
              }
            }),
            _vm._v(" "),
            _c("FilterField", {
              attrs: {
                label: "Eligible Voters",
                filter: _vm.profile.eligibleVoterFilter
              }
            }),
            _vm._v(" "),
            _c("FilterField", {
              attrs: {
                label: "Election Turnout",
                filter: _vm.profile.turnoutFilter
              }
            }),
            _vm._v(" "),
            _c("FilterField", {
              attrs: {
                label: "Election Results",
                filter: _vm.profile.electionResultsFilter
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }