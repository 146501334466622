<template>
  <div :class="`clearfix ${inline ? 'inline' : ''}`">
    <label v-if="label">{{ label }}</label>
    <p :key="option.value" v-for="option in options">
      <label>
        <input :name="group" :value="option.value" v-model="data[field]" :type="multi ? 'checkbox' : 'radio'" />
        <span>{{ option.label }}</span>
      </label>
    </p>
  </div>
</template>

<script>
export default {
  props: ['label','options','data','field','multi','inline'],
  data() {
    return {
      group: `options_${Math.round(Math.random()*10000)}`
    };
  }
}
</script>

<style scoped>
p {
  margin-right: 8px;
}
.inline p {
  display: inline-block;
}
[type="checkbox"]+span:not(.lever),
[type="radio"]+span:not(.lever) {
  padding-left: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
}
</style>
