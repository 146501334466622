<template>
  <div class="county row">
    <div v-if="!hideName" class="col l12 m12 s12">
      <div class="row data-row title-row">
        <div class="col l6 m6 s6">
          <div class="county-name">{{ county.name }} County</div>
        </div>
        <div class="col l6 m6 s6">
          <div ref="mpi" :class="`tooltipped minority-score score-${Math.ceil(county.getMinorityRepresentationScore() * 100 / 20)}`">
            <div class="score">
              {{ formatPercent(county.getMinorityRepresentationScore()) }}
            </div>
            <div class="label">MPI</div>
          </div>
        </div>
      </div>
    </div>
    <div :class="`col l12 m12 s12`">
      <div class="row data-row">
        <div class="col l6 m12 s12">
          <div class="row data-row">
            <div class="col l6 m6 s12 data-cell">
              <BarStackWithDetails :total="county.population" label="Population"
                :segments="county.getPopulationSegments(true)"/>
            </div>
            <div class="col l6 m6 s12 data-cell">
              <BarStackWithDetails label="Registered Voters"
                :total="county.voters.registered"
                :percent="county.voters.registered / county.population"
                :showSubBars="true"
                :segments="county.getRegisteredVoterSegments()"/>
            </div>
          </div>
        </div>
        <div class="col l6 m12 s12">
          <div class="row data-row">
            <div class="col l6 m6 s12 data-cell">
              <BarStackWithDetails v-if="county.estimateEligibleVoters() > 0" label="Est. Eligible Voters"
                :total="county.estimateEligibleVoters()"
                :percent="county.estimateEligibleVoters() / county.population"
                :showSubBars="true"
                :segments="county.getEligibleVoterSegments()"/>
              <div v-else class="message">
                Cannot estimate the number of eligible voters. Census data
                indicates there are fewer than <b>{{ formatNumber(county.voters.eligible) }}</b>
                residents of voting age, which is lower than the number of people
                already registerd to vote.
              </div>
            </div>
            <div class="col l6 m6 s12 data-cell">
              <div class="clearfix stack-title">
                <div class="left">
                  {{ formatNumber(county.votes.president.total) }}
                </div>
                <div class="right">
                  {{ formatPercent(county.votes.president.total / county.voters.registered) }}
                </div>
                <div class="responsive-text">Voted</div>
              </div>
              <BarStack class="small" :segments="[{count:county.votes.president.total,color:'#888'},{count:county.voters.registered-county.votes.president.total,color:'#eee'}]" />
              <PartisanLeanCell :county="county" label="President" :include="['president']" />
              <PartisanLeanCell :county="county" label="Other Elections" :exclude="['president']" :max="5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarStack from './BarStack.vue';
import BarStackWithDetails from './BarStackWithDetails.vue';
import PartisanLeanCell from './datacells/PartisanLeanCell.vue';
export default {
  components: {BarStack,BarStackWithDetails,PartisanLeanCell},
  props: ['county', 'hideName'],
  data() {
    return {};
  },
  mounted() {
    M.Tooltip.init(this.$refs.mpi, {
      html: this.tooltipHtml
    });
  },
  computed: {
    tooltipHtml: function() {
      return `
        ${this.formatPercent(this.county.getMinorityRepresentationScore())} Minority Participation Index<br />
        <small>
          Equal to the ratio
          of minority registration (${this.formatPercent(this.county.getMinorityRegistrationPercentage())})
          to white registration (${this.formatPercent(this.county.getWhiteRegistrationPercentage())})
        </small>
      `;
    }
  }
}
</script>

<style>
.county {
  font-size: 12px;
}
.county .county-name {
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}
.county .data-cell {
  margin-top: 0px;
  margin-bottom: 20px;
}
.county .row.data-row:not(.title-row) {
  margin-bottom: 0px;
}
.message {
  text-align: center;
  color: #888;
  margin: 12px auto;
}
.stack-title {
  font-weight: 500;
}
.stack-title .left {
  margin-right: 4px;
}
.stack-title .right {
  margin-left: 4px;
}
.bar-stack {
  margin: 2px 0px;
  height: 14px;
  border-radius: 2px;
  overflow: hidden;
}
.bar-stack.small {
  height: 7px;
}
.bar-stack.vertical {
  height: 126px;
  width: 16px;
  display: inline-block;
  margin: 0px 2px;
}
.bar-stack.vertical.small {
  width: 7px;
}
.data-cell table tr {
  border: none;
}
.data-cell table th {
  font-weight: normal;
}
.data-cell table tr td,
.data-cell table tr th {
  padding: 0px 2px;
}
.minority-score {
  font-weight: bold;
  text-align: center;
}
.minority-score .score {
  text-align: center;
  font-size: 12px;
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  color: #fff;
  margin: 2px auto;
  display: inline-block;
}
.minority-score .label {
  display: block;
}
.minority-score.score-1 > .score {
  background-color: #d50000;
}
.minority-score.score-2 > .score {
  background-color: #dd2c00;
}
.minority-score.score-3 > .score {
  background-color: #ffab00;
}
.minority-score.score-4 > .score {
  background-color: #ffd600;
}
.minority-score.score-5 > .score,
.minority-score.score-6 > .score,
.minority-score.score-7 > .score {
  background-color: #00c853;
}
.minority-score.score-1 > .label {
  background-color: #d50000;
}
.minority-score.score-2 > .label {
  color: #dd2c00;
}
.minority-score.score-3 > .label {
  color: #ffab00;
}
.minority-score.score-4 > .label {
  color: #ffd600;
}
.minority-score.score-5 > .label,
.minority-score.score-6 > .label,
.minority-score.score-7 > .label {
  color: #00c853;
}
/* @media only screen and (max-width: 992px) { */
  .county .county-name {
    float: left;
    line-height: 36px;
    margin-right: 8px;
    font-size: 18px;
  }
  .minority-score {
    float: right;
  }
  .minority-score .score {
    float: right;
    margin: 0px 0px 0px 4px;
  }
  .minority-score .label {
    line-height: 36px;
    float: left;
  }
/* } */
</style>
