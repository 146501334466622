var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12" }, [
        _c("label", [_vm._v("Campaign Name")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.campaign.name,
              expression: "campaign.name"
            }
          ],
          attrs: { type: "text", placeholder: "Best Campaign Ever" },
          domProps: { value: _vm.campaign.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.campaign, "name", $event.target.value)
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col s12" },
        [
          _c("label", [_vm._v("What type of voters are you looking for?")]),
          _vm._v(" "),
          _c("SelectionView", {
            attrs: {
              model: _vm.campaign.voterProfile,
              field: "scope",
              options: [
                { value: "registered", label: "Registered to vote" },
                { value: "eligible", label: "Eligible to vote" },
                { value: "all", label: "Registered or eligible" },
                { value: "voted", label: "Voted in the last election" }
              ]
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.campaign.voterProfile.scope === "voted"
        ? _c(
            "div",
            { staticClass: "col s12" },
            [
              _c("label", [_vm._v("Voters of a particular party?")]),
              _vm._v(" "),
              _c("SelectionView", {
                attrs: {
                  model: _vm.campaign.voterProfile,
                  field: "party",
                  inline: true,
                  options: [
                    { value: "either", label: "Any" },
                    { value: "D", label: "Democrat" },
                    { value: "R", label: "Republican" }
                  ]
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "col s12" },
            [
              _c("label", [_vm._v("Voters of a particular race?")]),
              _vm._v(" "),
              _c("SelectionView", {
                attrs: {
                  model: _vm.campaign.voterProfile,
                  field: "races",
                  inline: true,
                  multi: true,
                  options: [
                    { value: "white", label: "White" },
                    { value: "black", label: "Black" },
                    { value: "hispanic", label: "Hispanic" },
                    { value: "other", label: "Other" }
                  ]
                }
              })
            ],
            1
          )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12" }, [
        _c("label", [
          _vm._v("How would you like to identify target communities?")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter-field" },
          [
            _c("p", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.campaign.communityProfile.populationFilter.enabled,
                      expression:
                        "campaign.communityProfile.populationFilter.enabled"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.campaign.communityProfile.populationFilter.enabled
                    )
                      ? _vm._i(
                          _vm.campaign.communityProfile.populationFilter
                            .enabled,
                          null
                        ) > -1
                      : _vm.campaign.communityProfile.populationFilter.enabled
                  },
                  on: {
                    change: function($event) {
                      var $$a =
                          _vm.campaign.communityProfile.populationFilter
                            .enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.campaign.communityProfile.populationFilter,
                              "enabled",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.campaign.communityProfile.populationFilter,
                              "enabled",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.campaign.communityProfile.populationFilter,
                          "enabled",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("By Population")])
              ])
            ]),
            _vm._v(" "),
            _vm.campaign.communityProfile.populationFilter.enabled
              ? [
                  _c("SelectionView", {
                    attrs: {
                      label: "Race",
                      model: _vm.campaign.communityProfile.populationFilter,
                      field: "races",
                      inline: true,
                      multi: true,
                      options: [
                        { value: "white", label: "White" },
                        { value: "black", label: "Black" },
                        { value: "hispanic", label: "Hispanic" },
                        { value: "other", label: "Other" }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("SelectionView", {
                    staticClass: "inline",
                    attrs: {
                      model: _vm.campaign.communityProfile.populationFilter,
                      field: "op",
                      inline: true,
                      options: [
                        { value: "gt", label: "At Least" },
                        { value: "lt", label: "At Most" }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "inline", staticStyle: { width: "100px" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.campaign.communityProfile.populationFilter
                                .value,
                            expression:
                              "campaign.communityProfile.populationFilter.value"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value:
                            _vm.campaign.communityProfile.populationFilter.value
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.campaign.communityProfile.populationFilter,
                              "value",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter-field" },
          [
            _c("p", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.campaign.communityProfile.registeredVoterFilter
                          .enabled,
                      expression:
                        "campaign.communityProfile.registeredVoterFilter.enabled"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.campaign.communityProfile.registeredVoterFilter
                        .enabled
                    )
                      ? _vm._i(
                          _vm.campaign.communityProfile.registeredVoterFilter
                            .enabled,
                          null
                        ) > -1
                      : _vm.campaign.communityProfile.registeredVoterFilter
                          .enabled
                  },
                  on: {
                    change: function($event) {
                      var $$a =
                          _vm.campaign.communityProfile.registeredVoterFilter
                            .enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.campaign.communityProfile
                                .registeredVoterFilter,
                              "enabled",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.campaign.communityProfile
                                .registeredVoterFilter,
                              "enabled",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.campaign.communityProfile.registeredVoterFilter,
                          "enabled",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("By Registered Voters")])
              ])
            ]),
            _vm._v(" "),
            _vm.campaign.communityProfile.registeredVoterFilter.enabled
              ? [
                  _c("SelectionView", {
                    attrs: {
                      label: "Race",
                      model:
                        _vm.campaign.communityProfile.registeredVoterFilter,
                      field: "races",
                      inline: true,
                      multi: true,
                      options: [
                        { value: "white", label: "White" },
                        { value: "black", label: "Black" },
                        { value: "hispanic", label: "Hispanic" },
                        { value: "other", label: "Other" }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("SelectionView", {
                    staticClass: "inline",
                    attrs: {
                      model:
                        _vm.campaign.communityProfile.registeredVoterFilter,
                      field: "op",
                      inline: true,
                      options: [
                        { value: "gt", label: "At Least" },
                        { value: "lt", label: "At Most" }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "inline", staticStyle: { width: "100px" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.campaign.communityProfile
                                .registeredVoterFilter.value,
                            expression:
                              "campaign.communityProfile.registeredVoterFilter.value"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value:
                            _vm.campaign.communityProfile.registeredVoterFilter
                              .value
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.campaign.communityProfile
                                .registeredVoterFilter,
                              "value",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter-field" },
          [
            _c("p", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.campaign.communityProfile.eligibleVoterFilter
                          .enabled,
                      expression:
                        "campaign.communityProfile.eligibleVoterFilter.enabled"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.campaign.communityProfile.eligibleVoterFilter.enabled
                    )
                      ? _vm._i(
                          _vm.campaign.communityProfile.eligibleVoterFilter
                            .enabled,
                          null
                        ) > -1
                      : _vm.campaign.communityProfile.eligibleVoterFilter
                          .enabled
                  },
                  on: {
                    change: function($event) {
                      var $$a =
                          _vm.campaign.communityProfile.eligibleVoterFilter
                            .enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.campaign.communityProfile.eligibleVoterFilter,
                              "enabled",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.campaign.communityProfile.eligibleVoterFilter,
                              "enabled",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.campaign.communityProfile.eligibleVoterFilter,
                          "enabled",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("By Eligible Voters")])
              ])
            ]),
            _vm._v(" "),
            _vm.campaign.communityProfile.eligibleVoterFilter.enabled
              ? [
                  _c("SelectionView", {
                    attrs: {
                      label: "Race",
                      model: _vm.campaign.communityProfile.eligibleVoterFilter,
                      field: "races",
                      inline: true,
                      multi: true,
                      options: [
                        { value: "white", label: "White" },
                        { value: "black", label: "Black" },
                        { value: "hispanic", label: "Hispanic" },
                        { value: "other", label: "Other" }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("SelectionView", {
                    staticClass: "inline",
                    attrs: {
                      model: _vm.campaign.communityProfile.eligibleVoterFilter,
                      field: "op",
                      inline: true,
                      options: [
                        { value: "gt", label: "At Least" },
                        { value: "lt", label: "At Most" }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "inline", staticStyle: { width: "100px" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.campaign.communityProfile.eligibleVoterFilter
                                .value,
                            expression:
                              "campaign.communityProfile.eligibleVoterFilter.value"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value:
                            _vm.campaign.communityProfile.eligibleVoterFilter
                              .value
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.campaign.communityProfile.eligibleVoterFilter,
                              "value",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter-field" },
          [
            _c("p", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.campaign.communityProfile.turnoutFilter.enabled,
                      expression:
                        "campaign.communityProfile.turnoutFilter.enabled"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.campaign.communityProfile.turnoutFilter.enabled
                    )
                      ? _vm._i(
                          _vm.campaign.communityProfile.turnoutFilter.enabled,
                          null
                        ) > -1
                      : _vm.campaign.communityProfile.turnoutFilter.enabled
                  },
                  on: {
                    change: function($event) {
                      var $$a =
                          _vm.campaign.communityProfile.turnoutFilter.enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.campaign.communityProfile.turnoutFilter,
                              "enabled",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.campaign.communityProfile.turnoutFilter,
                              "enabled",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.campaign.communityProfile.turnoutFilter,
                          "enabled",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("By Turnout")])
              ])
            ]),
            _vm._v(" "),
            _vm.campaign.communityProfile.turnoutFilter.enabled
              ? [
                  _c("SelectionView", {
                    staticClass: "inline",
                    attrs: {
                      model: _vm.campaign.communityProfile.turnoutFilter,
                      field: "op",
                      inline: true,
                      options: [
                        { value: "gt", label: "At Least" },
                        { value: "lt", label: "At Most" }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "inline", staticStyle: { width: "100px" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.campaign.communityProfile.turnoutFilter.value,
                            expression:
                              "campaign.communityProfile.turnoutFilter.value"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value:
                            _vm.campaign.communityProfile.turnoutFilter.value
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.campaign.communityProfile.turnoutFilter,
                              "value",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter-field" },
          [
            _c("p", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.campaign.communityProfile.electionResultsFilter
                          .enabled,
                      expression:
                        "campaign.communityProfile.electionResultsFilter.enabled"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.campaign.communityProfile.electionResultsFilter
                        .enabled
                    )
                      ? _vm._i(
                          _vm.campaign.communityProfile.electionResultsFilter
                            .enabled,
                          null
                        ) > -1
                      : _vm.campaign.communityProfile.electionResultsFilter
                          .enabled
                  },
                  on: {
                    change: function($event) {
                      var $$a =
                          _vm.campaign.communityProfile.electionResultsFilter
                            .enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.campaign.communityProfile
                                .electionResultsFilter,
                              "enabled",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.campaign.communityProfile
                                .electionResultsFilter,
                              "enabled",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.campaign.communityProfile.electionResultsFilter,
                          "enabled",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("By Who They Voted For")])
              ])
            ]),
            _vm._v(" "),
            _vm.campaign.communityProfile.electionResultsFilter.enabled
              ? [
                  _c("SelectionView", {
                    staticClass: "inline",
                    attrs: {
                      model:
                        _vm.campaign.communityProfile.electionResultsFilter,
                      field: "winner",
                      inline: true,
                      options: [
                        { value: "D", label: "Biden" },
                        { value: "R", label: "Trump" }
                      ]
                    }
                  })
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }