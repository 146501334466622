<template>
  <div>
    <Toolbar :showMapLink="true" />
    <LoaderView v-if="!model.loaded" message="Loading counties"/>
    <template v-else>
      <div class="counties-stats">
        <div class="row">
          <div class="col s12">
            <div :class="`filter-title ${filterTitle.length > 40 ? 'compact' : ''}`" v-html="filterTitle"/>
            <CountyView :county="model.aggregate()" :hideName="true" />
          </div>
        </div>
      </div>
      <div class="counties-list">
        <CountyView :class="`county-item ${county.hidden ? 'hide' : ''}`" :key="county.model.fips" v-for="county in model.counties" :county="county.model" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.model.reloadCounties();
  },
  computed: {
    filterTitle: function() {
      return 'Showing ' + this.model.getSearchDescription();
    }
  }
}
</script>

<style scoped>
.filter-title {
  font-size: 20px;
  margin: 16px 0px 8px;
}
.filter-title.compact {
  font-size: 16px;
}
.counties-stats {
  /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2); */
  border-bottom: 1px solid #ccc;
  background-color: #f8f8f8;
}
.counties-stats .row {
  margin-bottom: 0px;
}
.counties-list {
  padding-top: 24px;
  /* border-top: 1px solid #888; */
}
.counties-list .county-item {
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;
  padding-bottom: 16px;
}
.counties-list .county-item .row:last-child {
  margin-bottom: 0px;
}

.filter-content {
  line-height: 28px;
}
.filter-content input {
  line-height: 26px;
  height: 26px;
  padding: 0px 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  width: auto;
}
.filter-content input[type=number] {
  width: 64px;
}
</style>
