var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12 m10 offset-m1 l8 offset-l2 center" }, [
        _c("div", { staticClass: "title-1" }, [_vm._v("Blue Peach")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "title-2" }, [_vm._v("Analytics")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s12" }, [
            _c("p", [
              _vm._v("\n            Only "),
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm
                      .moment("20201207", "YYYYMMDD")
                      .diff(_vm.moment(), "days")
                  ) + "\n            days"
                )
              ]),
              _vm._v(
                " remain before the voter registration deadline for the Georgia\n            runoff election.\n          "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            Our software empowers organizers to focus their resources where they\n            will have the greatest impact. Organizers can use our tool to define\n            profiles of would-be Democractic voters, and quickly identify communities\n            projected to match these profiles. Get started below!\n          "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col s6 big-link" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "list", params: {} } } },
                [
                  _c("i", { staticClass: "material-icons medium" }, [
                    _vm._v("list")
                  ]),
                  _c("br"),
                  _vm._v("\n            County List\n          ")
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col s6 big-link" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "map", params: {} } } },
                [
                  _c("i", { staticClass: "material-icons medium" }, [
                    _vm._v("map")
                  ]),
                  _c("br"),
                  _vm._v("\n            County Map\n          ")
                ]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-bar" }, [
      _c("img", { attrs: { src: "/favicon.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }