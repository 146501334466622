var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "barStack",
      class: "bar-stack " + (_vm.isVertical ? "vertical" : "horizontal")
    },
    _vm._l(_vm.bars, function(bar) {
      return _c("div", {
        staticClass: "bar tooltipped",
        style: bar.style,
        attrs: { "data-tooltip": bar.tooltip }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }