<template>
  <div>
    <div class="row">
      <div class="col s12">
        <label>Campaign Name</label>
        <input type="text" v-model="campaign.name" placeholder="Best Campaign Ever" />
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <label>What type of voters are you looking for?</label>
        <SelectionView :model="campaign.voterProfile" field="scope"
          :options="[{value:'registered',label:'Registered to vote'},{value:'eligible',label:'Eligible to vote'},{value:'all',label:'Registered or eligible'},{value:'voted',label:'Voted in the last election'}]"/>
        <!-- <select class="browser-default" v-model="campaign.voterProfile.scope">
          <option value="registered">Registered voters</option>
          <option value="eligible">Eligible voters</option>
          <option value="all">Registered and eligible voters</option>
          <option value="voted">Voters who voted in the most recent election</option>
        </select> -->
      </div>
      <div v-if="campaign.voterProfile.scope === 'voted'" class="col s12">
        <label>Voters of a particular party?</label>
        <SelectionView :model="campaign.voterProfile" field="party" :inline="true"
          :options="[{value:'either',label:'Any'},{value:'D',label:'Democrat'},{value:'R',label:'Republican'}]" />
      </div>
      <div v-else class="col s12">
        <label>Voters of a particular race?</label>
        <SelectionView :model="campaign.voterProfile" field="races" :inline="true" :multi="true"
          :options="[{value:'white',label:'White'},{value:'black',label:'Black'},{value:'hispanic',label:'Hispanic'},{value:'other',label:'Other'}]" />
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <label>How would you like to identify target communities?</label>
        <div class="filter-field">
          <p>
            <label>
              <input type="checkbox" v-model="campaign.communityProfile.populationFilter.enabled" />
              <span>By Population</span>
            </label>
          </p>
          <template v-if="campaign.communityProfile.populationFilter.enabled">
            <SelectionView label="Race" :model="campaign.communityProfile.populationFilter" field="races" :inline="true" :multi="true"
              :options="[{value:'white',label:'White'},{value:'black',label:'Black'},{value:'hispanic',label:'Hispanic'},{value:'other',label:'Other'}]" />
            <SelectionView :model="campaign.communityProfile.populationFilter" field="op" :inline="true"
              :options="[{value:'gt',label:'At Least'},{value:'lt',label:'At Most'}]" class="inline"/>
            <div class="inline" style="width:100px">
              <input type="text" v-model="campaign.communityProfile.populationFilter.value" />
            </div>
          </template>
        </div>
        <div class="filter-field">
          <p>
            <label>
              <input type="checkbox" v-model="campaign.communityProfile.registeredVoterFilter.enabled" />
              <span>By Registered Voters</span>
            </label>
          </p>
          <template v-if="campaign.communityProfile.registeredVoterFilter.enabled">
            <SelectionView label="Race" :model="campaign.communityProfile.registeredVoterFilter" field="races" :inline="true" :multi="true"
              :options="[{value:'white',label:'White'},{value:'black',label:'Black'},{value:'hispanic',label:'Hispanic'},{value:'other',label:'Other'}]" />
            <SelectionView :model="campaign.communityProfile.registeredVoterFilter" field="op" :inline="true"
              :options="[{value:'gt',label:'At Least'},{value:'lt',label:'At Most'}]" class="inline"/>
            <div class="inline" style="width:100px">
              <input type="text" v-model="campaign.communityProfile.registeredVoterFilter.value" />
            </div>
          </template>
        </div>
        <div class="filter-field">
          <p>
            <label>
              <input type="checkbox" v-model="campaign.communityProfile.eligibleVoterFilter.enabled" />
              <span>By Eligible Voters</span>
            </label>
          </p>
          <template v-if="campaign.communityProfile.eligibleVoterFilter.enabled">
            <SelectionView label="Race" :model="campaign.communityProfile.eligibleVoterFilter" field="races" :inline="true" :multi="true"
              :options="[{value:'white',label:'White'},{value:'black',label:'Black'},{value:'hispanic',label:'Hispanic'},{value:'other',label:'Other'}]" />
            <SelectionView :model="campaign.communityProfile.eligibleVoterFilter" field="op" :inline="true"
              :options="[{value:'gt',label:'At Least'},{value:'lt',label:'At Most'}]" class="inline"/>
            <div class="inline" style="width:100px">
              <input type="text" v-model="campaign.communityProfile.eligibleVoterFilter.value" />
            </div>
          </template>
        </div>
        <div class="filter-field">
          <p>
            <label>
              <input type="checkbox" v-model="campaign.communityProfile.turnoutFilter.enabled" />
              <span>By Turnout</span>
            </label>
          </p>
          <template v-if="campaign.communityProfile.turnoutFilter.enabled">
            <SelectionView :model="campaign.communityProfile.turnoutFilter" field="op" :inline="true"
              :options="[{value:'gt',label:'At Least'},{value:'lt',label:'At Most'}]" class="inline"/>
            <div class="inline" style="width:100px">
              <input type="text" v-model="campaign.communityProfile.turnoutFilter.value" />
            </div>
          </template>
        </div>
        <div class="filter-field">
          <p>
            <label>
              <input type="checkbox" v-model="campaign.communityProfile.electionResultsFilter.enabled" />
              <span>By Who They Voted For</span>
            </label>
          </p>
          <template v-if="campaign.communityProfile.electionResultsFilter.enabled">
            <SelectionView :model="campaign.communityProfile.electionResultsFilter" field="winner" :inline="true"
              :options="[{value:'D',label:'Biden'},{value:'R',label:'Trump'}]" class="inline"/>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignModel from './models/CampaignModel.js';
import SelectionView from './forms/SelectionView.vue';
export default {
  components: {SelectionView},
  // props: ['campaign'],
  data() {
    return {
      campaign: new CampaignModel()
    };
  },
  mounted() {
    // var elems = document.querySelectorAll('select');
    // M.FormSelect.init(elems);
  }
}
</script>

<style scoped>
label {
  color: #333;
  font-size: 13px;
  margin: 4px 0px;
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  position: relative;
}
input[type=text], select {
  border: 1px solid #ccc;
  padding: 0px 8px;
  width: calc(100% - 18px);
}
select {
  width: 100%;
}
select:focus {
  border: 1px solid #2196F3;
}
.inline {
  display: inline-block;
}
[type="checkbox"]+span:not(.lever),
[type="radio"]+span:not(.lever) {
  padding-left: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
}
</style>
