const moment = require('moment');

function formatNumberWithSuffix(num, suffix) {
  if (num >= 100) {
    return Math.round(num) + suffix;
  } else if (num >= 10) {
    return Math.round(10*num)/10 + suffix;
  } else {
    return Math.round(100*num)/100 + suffix;
  }
}

function formatNumber(num, abbreviated) {
  if (abbreviated) {
    if (num >= 1000000000) {
      return formatNumberWithSuffix(num / 1000000000, 'B');
    } else if (num >= 1000000) {
      return formatNumberWithSuffix(num / 1000000, 'M');
    } else if (num >= 1000) {
      return formatNumberWithSuffix(num / 1000, 'K');
    } else {
      return formatNumberWithSuffix(num, '');
    }
  } else {
    if (num > 100) {
      return Math.round(num).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
      return Math.round(100*num)/100;
    }
  }
}

/**
 * Convenience method for formatting dates.
 * @param date Can be a Date object or a date string with format 'YYYYMMDD'
 * @param format Can be a valid date format, or 'short' or 'long'. If blank,
 *          it defaults to 'MMM D'
 */
function formatDate(date, format) {
  if (!date) {
    return '';
  }
  var m = date instanceof Date ? moment(date) : moment(date, 'YYYYMMDD');
  if (format === 'short') {
    format = 'MM/DD';
  } else if (format === 'long') {
    format = 'MMMM D';
  } else if (!format) {
    format = 'MMM D';
  }
  return m.format(format);
}

function formatRatio(num, denom) {
  if (denom) num = num / denom;
  var numer = 1;
  denom = 1 / num;
  if (denom < 2) {
    numer = Math.round(10 / denom);
    denom = 10;
  } else if (denom < 10) {
    numer = Math.round(20 / denom);
    denom = 20;
  } else if (denom < 100) {
    denom = 5 * Math.round(denom / 5);
  } else if (denom < 250) {
    denom = 25 * Math.round(denom / 25);
  } else if (denom < 500) {
    denom = 50 * Math.round(denom / 50);
  } else if (denom < 1000) {
    denom = 100 * Math.round(denom / 100);
  } else if (denom < 5000) {
    denom = 500 * Math.round(denom / 500);
  } else if (denom < 10000) {
    denom = 1000 * Math.round(denom / 1000);
  } else {
    denom = Math.round(denom);
  }
  while (numer % 2 == 0 && denom % 2 == 0) {
    numer /= 2;
    denom /= 2;
  }
  return `${formatNumber(numer)} in ${formatNumber(denom)}`;
}

function formatPercent(num) {
  if (num === 0) {
    return '0%';
  } else if (num > 0 && num < 0.01) {
    return `<1%`;
  } else {
    return `${Math.round(100 * num)}%`
  }
}

function formatLean(percentDiff, positiveLabel, negativeLabel) {
  var points = Math.abs(Math.round(1000 * percentDiff) / 10);
  if (points > 3) {
    points = Math.round(points);
  }
  var label = percentDiff < 0 ? negativeLabel : positiveLabel;
  return `${label} +${Math.abs(points)}`;
}

module.exports = {
  formatNumber,
  formatPercent,
  formatDate,
  formatRatio,
  formatLean
}
