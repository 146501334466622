var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "county row" }, [
    !_vm.hideName
      ? _c("div", { staticClass: "col l12 m12 s12" }, [
          _c("div", { staticClass: "row data-row title-row" }, [
            _c("div", { staticClass: "col l6 m6 s6" }, [
              _c("div", { staticClass: "county-name" }, [
                _vm._v(_vm._s(_vm.county.name) + " County")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col l6 m6 s6" }, [
              _c(
                "div",
                {
                  ref: "mpi",
                  class:
                    "tooltipped minority-score score-" +
                    Math.ceil(
                      (_vm.county.getMinorityRepresentationScore() * 100) / 20
                    )
                },
                [
                  _c("div", { staticClass: "score" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.formatPercent(
                            _vm.county.getMinorityRepresentationScore()
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [_vm._v("MPI")])
                ]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { class: "col l12 m12 s12" }, [
      _c("div", { staticClass: "row data-row" }, [
        _c("div", { staticClass: "col l6 m12 s12" }, [
          _c("div", { staticClass: "row data-row" }, [
            _c(
              "div",
              { staticClass: "col l6 m6 s12 data-cell" },
              [
                _c("BarStackWithDetails", {
                  attrs: {
                    total: _vm.county.population,
                    label: "Population",
                    segments: _vm.county.getPopulationSegments(true)
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col l6 m6 s12 data-cell" },
              [
                _c("BarStackWithDetails", {
                  attrs: {
                    label: "Registered Voters",
                    total: _vm.county.voters.registered,
                    percent:
                      _vm.county.voters.registered / _vm.county.population,
                    showSubBars: true,
                    segments: _vm.county.getRegisteredVoterSegments()
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col l6 m12 s12" }, [
          _c("div", { staticClass: "row data-row" }, [
            _c(
              "div",
              { staticClass: "col l6 m6 s12 data-cell" },
              [
                _vm.county.estimateEligibleVoters() > 0
                  ? _c("BarStackWithDetails", {
                      attrs: {
                        label: "Est. Eligible Voters",
                        total: _vm.county.estimateEligibleVoters(),
                        percent:
                          _vm.county.estimateEligibleVoters() /
                          _vm.county.population,
                        showSubBars: true,
                        segments: _vm.county.getEligibleVoterSegments()
                      }
                    })
                  : _c("div", { staticClass: "message" }, [
                      _vm._v(
                        "\n              Cannot estimate the number of eligible voters. Census data\n              indicates there are fewer than "
                      ),
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.formatNumber(_vm.county.voters.eligible))
                        )
                      ]),
                      _vm._v(
                        "\n              residents of voting age, which is lower than the number of people\n              already registerd to vote.\n            "
                      )
                    ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col l6 m6 s12 data-cell" },
              [
                _c("div", { staticClass: "clearfix stack-title" }, [
                  _c("div", { staticClass: "left" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.formatNumber(_vm.county.votes.president.total)
                        ) +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "right" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.formatPercent(
                            _vm.county.votes.president.total /
                              _vm.county.voters.registered
                          )
                        ) +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "responsive-text" }, [
                    _vm._v("Voted")
                  ])
                ]),
                _vm._v(" "),
                _c("BarStack", {
                  staticClass: "small",
                  attrs: {
                    segments: [
                      {
                        count: _vm.county.votes.president.total,
                        color: "#888"
                      },
                      {
                        count:
                          _vm.county.voters.registered -
                          _vm.county.votes.president.total,
                        color: "#eee"
                      }
                    ]
                  }
                }),
                _vm._v(" "),
                _c("PartisanLeanCell", {
                  attrs: {
                    county: _vm.county,
                    label: "President",
                    include: ["president"]
                  }
                }),
                _vm._v(" "),
                _c("PartisanLeanCell", {
                  attrs: {
                    county: _vm.county,
                    label: "Other Elections",
                    exclude: ["president"],
                    max: 5
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }