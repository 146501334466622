var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "partisan-lean-cell" },
    [
      _c("div", { staticClass: "stack-title" }, [
        _c("div", {
          staticClass: "right lean-value",
          style: { color: _vm.leanColor },
          domProps: { textContent: _vm._s(_vm.formatLean(_vm.lean, "R", "D")) }
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "responsive-text",
          domProps: { textContent: _vm._s(_vm.label) }
        })
      ]),
      _vm._v(" "),
      _vm._l(_vm.segmentGroups, function(group) {
        return _c("BarStack", {
          key: group.contest,
          staticClass: "small",
          attrs: { segments: group.segments }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }