import Vue from 'vue'
import VueRouter from 'vue-router'

import API from './API.js'
import SearchModel from './models/SearchModel.js';
import HomeView from './HomeView.vue'
import MainView from './MainView.vue'
import MapView from './MapView.vue'
import LoaderView from './LoaderView.vue'
import Toolbar from './Toolbar.vue'
import CountyView from './CountyView.vue'
import CampaignView from './CampaignView.vue'
const { formatDate, formatNumber, formatPercent, formatRatio, formatLean } = require('./utils.js')
import moment from 'moment';

Promise.prototype.finally = Promise.prototype.finally || function(onFinally) {
  return this.then(onFinally, onFinally);
};

Vue.use(VueRouter);

const routes = [
  {
    name: 'home',
    path: '/',
    // component: HomeView,
    redirect: 'list'
  },
  {
    name: 'list',
    path: '/list',
    component: MainView
  },
  {
    name: 'map',
    path: '/map',
    component: MapView
  },
  {
    name: 'campaign',
    path: '/campaign',
    component: CampaignView
  }
];

const router = new VueRouter({
  routes,
});

const SHARED_MODEL = new SearchModel(API);

Vue.mixin({
  components: {LoaderView,Toolbar,CountyView},
  data() {
    return {
      API,
      moment,
      model: SHARED_MODEL
    }
  },
  methods: {
    formatNumber,
    formatDate,
    formatRatio,
    formatPercent,
    formatLean
  }
})

new Vue({
  el: '#app',
  router
});
