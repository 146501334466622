<template>
  <div :class="`bar-stack ${isVertical ? 'vertical' : 'horizontal'}`" ref="barStack">
    <div v-for="bar in bars" class="bar tooltipped" :data-tooltip="bar.tooltip" :style="bar.style"/>
  </div>
</template>

<script>
// Segment: { label, count, color }
export default {
  props: ['segments', 'orientation'],
  data() {
    return {};
  },
  mounted() {
    // var elems = $(this.$refs.barStack).find('.tooltipped')
    // M.Tooltip.init(elems, {
    //   enterDelay: 0
    // });
  },
  computed: {
    isVertical: function() {
      return this.orientation === 'vertical';
    },
    bars: function() {
      var total = 0;
      this.segments.forEach(segment => total += segment.count || 0);
      var offset = 0;
      return this.segments.map(segment => {
        var style = {'background-color': segment.color}
        style[this.isVertical ? 'height' : 'width'] = `${100 * (segment.count||0) / total}%`;
        style[this.isVertical ? 'bottom' : 'left'] = `${100 * offset / total}%`
        var bar = {
          tooltip: segment.tooltip || `${this.formatNumber(segment.count)} ${segment.label}`,
          label: segment.label,
          count: segment.count,
          style
        };
        offset += segment.count || 0;
        return bar;
      });
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.bar-stack {
  position: relative;
}
/* .bar-stack.vertical {
  width: 16px;
  height: 96px;
}
.bar-stack.horizontal {
  width: 96px;
  height: 16px;
} */
.bar-stack .bar {
  position: absolute;
}
/* .bar-stack:hover .bar {
  opacity: 0.5;
}
.bar-stack:hover .bar:hover {
  opacity: 1;
} */
.bar-stack.vertical .bar {
  left: 0px;
  width: 100%;
  /* border-top: 1px solid #fff; */
}
.bar-stack.horizontal .bar {
  top: 0px;
  height: 100%;
  /* border-left: 1px solid #fff; */
}
.bar-stack .bar:first-child {
  border-top: none;
  border-left: none;
}
</style>
