var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "clearfix stack-title" }, [
        !_vm.hideTotal
          ? _c("div", { staticClass: "left" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.formatNumber(_vm.total)) + "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.percent != null
          ? _c("div", { staticClass: "right" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.formatPercent(_vm.percent)) + "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-text" },
          [
            !_vm.hideTotal ? void 0 : _vm._e(),
            _vm._v(_vm._s(_vm.label || "Total") + "\n    ")
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("BarStack", { attrs: { segments: _vm.segments } }),
      _vm._v(" "),
      _c("table", [
        _c(
          "tbody",
          _vm._l(_vm.segments, function(segment) {
            return _c("tr", { style: { color: segment.color } }, [
              _c("td", { attrs: { align: "right" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.formatNumber(Math.round(segment.count))) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _vm.showSubBars && segment.percent && !segment.hidePercent
                ? _c(
                    "td",
                    { attrs: { width: "100%" } },
                    [
                      _c("BarStack", {
                        attrs: {
                          segments: [
                            { color: segment.color, count: segment.percent },
                            {
                              color: segment.color + "22",
                              count: 1 - segment.percent
                            }
                          ]
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "td",
                    {
                      staticClass: "responsive-text",
                      attrs: { width: "100%" }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(segment.label) + "\n        "
                      )
                    ]
                  ),
              _vm._v(" "),
              _c("td", { attrs: { align: "right" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      segment.hidePercent
                        ? ""
                        : _vm.formatPercent(
                            segment.percent == null
                              ? segment.count / _vm.total
                              : segment.percent
                          )
                    ) +
                    "\n        "
                )
              ])
            ])
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }