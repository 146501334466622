var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-field" }, [
    _c("h6", [_vm._v(_vm._s(_vm.label))]),
    _vm._v(" "),
    _c("div", { staticClass: "value-range" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.filter.min,
            expression: "filter.min"
          }
        ],
        attrs: { type: "number", placeholder: "Min" },
        domProps: { value: _vm.filter.min },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.filter, "min", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _vm.filter.type === "percent"
        ? _c("span", { staticClass: "percent-label" }, [_vm._v(" % ")])
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(" - ")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.filter.max,
            expression: "filter.max"
          }
        ],
        attrs: { type: "number", placeholder: "Max" },
        domProps: { value: _vm.filter.max },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.filter, "max", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _vm.filter.type === "percent"
        ? _c("span", { staticClass: "percent-label" }, [_vm._v(" % ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.filter.contestOptions ? _c("span", [_vm._v(" % margin ")]) : _vm._e()
    ]),
    _vm._v(" "),
    _vm.filter.raceOptions
      ? _c(
          "div",
          { staticClass: "race-selection" },
          [
            _c("SelectionView", {
              attrs: {
                inline: true,
                multi: true,
                options: _vm.filter.raceOptions,
                data: _vm.filter,
                field: "race"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.filter.contestOptions
      ? _c(
          "div",
          { staticClass: "contest-selection" },
          [
            _c("SelectionView", {
              attrs: {
                inline: true,
                options: _vm.filter.contestOptions,
                data: _vm.filter,
                field: "winner"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }