<template>
  <div>
    <Toolbar :showListLink="true" />
    <div class="map-holder">
      <LoaderView message="Loading map" v-if="!model.loaded || !map"/>
      <div id="map"></div>
      <div :class="`county-detail-modal ${selectedCounty && countyModalOpen ? 'open' : ''}`">
        <a class="btn-close btn-flat" @click.prevent="countyModalOpen = false"><i class="material-icons">expand_more</i></a>
        <div class="county-detail-content">
          <CountyView v-if="selectedCounty" :county="selectedCounty"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountyView from './CountyView.vue';
export default {
  components: {CountyView},
  data() {
    return {
      map: null,
      selectedCounty: null,
      countyModalOpen: false
    };
  },
  mounted() {
    this.model.reloadCounties();
    window.initMap = () => this.initMap();
    var script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDN7jKIxNXgkRj9ygYIDsGWs1Vl0Gy8gDk&callback=initMap';
    document.body.appendChild(script);
  },
  watch: {
    'model.counties': function() {
      this.setupFeatures();
    },
    selectedCounty: function() {
      this.countyModalOpen = this.selectedCounty != null;
      this.resetStyles();
    },
    countyModalOpen: function() {
      this.resetStyles();
    },
    'model.query': function() {
      console.log(`Need to update which counties are visible`);
      this.resetStyles();
    },
    'model.communityProfile': function() {
      this.resetStyles();
    }
  },
  methods: {
    initMap: function() {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: {lat:32.749, lng:-83.388},
        zoom: 7,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false
      });
      var map = this.map;
      map.set('styles', [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            { visibility: "off" }
          ]
        },{
          featureType: "water",
          elementType: "labels",
          stylers: [
            { visibility: "off" }
          ]
        },{
          featureType: "road",
          elementType: "labels",
          stylers: [
            { visibility: "off" }
          ]
        },{
          featureType: "road",
          elementType: "geometry",
          stylers: [
            { visibility: "off" }
          ]
        }
      ]);

      map.data.loadGeoJson(`/data/counties.geo.json`, null, (features) => {
        console.log(`Loaded ${features.length} features`);
        this.setupFeatures();
      });

      this.resetStyles();

      map.data.addListener('mouseover', function(event) {
        var county = event.feature.getProperty('county');
        if (county && !county.hidden) {
          map.data.revertStyle();
          map.data.overrideStyle(event.feature, {
            strokeColor:'#0008',
            strokeWeight: 2,
            fillOpacity: 1,
            zIndex: 999999
          });
        }
      });
      map.data.addListener('mouseout', function(event) {
        map.data.revertStyle();
      });
      map.data.addListener('click', (event) => {
        this.selectFeature(event.feature, event.latLng);
      });
    },
    resetStyles: function() {
      this.map.data.setStyle(feature => {
        var style = {
          strokeWeight: 0.5,
          strokeColor: '#888',
          fillColor: '#2196F3'
        };
        var county = feature.getProperty('county');
        if (county) {
          if (county.hidden) {
            style.fillOpacity = 0;
            style.strokeOpacity = 0.5;
          } else {
            if (this.countyModalOpen && this.selectedCounty && this.selectedCounty.fips === county.model.fips) {
              style = {
                strokeColor:'#0008',
                strokeWeight: 2,
                fillOpacity: 1,
                zIndex: 999999
              };
            }
            // var score = county.model.getMinorityRegistrationPercentage();
            var score = county.model.getMinorityRepresentationScore();
            // style.fillOpacity = Math.min(1, county.model.minorityRepresentationScore());
            // style.fillOpacity = Math.min(1, 0.25 + score * score);
            style.fillOpacity = 0.75;
            style.fillColor = `hsl(207,90%,${Math.round(100-75*score)}%)`;
          }
        } else {
          console.log('Feature has no county')
          style.fillOpacity = 0.25;
        }
        return style;
      });
    },
    setupFeatures: function() {
      if (this.model.loaded && this.map) {
        var countyMap = {};
        this.model.counties.forEach(county => countyMap[county.model.fips] = county);
        this.map.data.forEach((feature) => {
          var county = countyMap[feature.getId()];
          feature.setProperty('county', county);
        });
      }
    },
    selectFeature: function(feature, latLng) {
      var county = feature.getProperty('county')
      this.selectedCounty = county && county.model;
    }
  }
}
</script>

<style>
.map-holder {
  position: fixed;
  top: 56px;
  left: 0;
  bottom: 0;
  width: 100%;
}
#map {
  width: 100%;
  height: 100%;
}
.county-detail-modal {
  position: fixed;
  bottom: 0;
  overflow: auto;
  background-color: #fff;
  max-height: 0%;
  bottom: 0;
  transition: max-height 250ms;
}
.county-detail-modal.open {
  max-height: 50%;
}
.county-detail-modal .btn-close {
  position: fixed;
  z-index: 99999999;
  left: 50%;
  margin-left: -28px;
}
.county-detail-content {
  margin: 18px 2px;
}

</style>
