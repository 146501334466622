function doGet(path) {
  return new Promise((resolve, reject) => {
    $.get(path).done(resolve).fail(reject);
  });
}

var API = {
  fetchCounties: function() {
    return doGet('/data/counties.json');
  },
  fetchCounty: function(fips) {
    return doGet(`/data/counties.${fips}.json`);
  }
};

module.exports = API;
