const CountyModel = require('./CountyModel.js');
const { CommunityProfile } = require('./CampaignModel.js');

function SearchModel(API) {
  this.API = API;
  this.loaded = false;
  this.counties = [];
  this.query = '';
  this.communityProfile = new CommunityProfile();
}
$.extend(SearchModel.prototype, {
  reloadCounties: function(force) {
    if (force || this.counties.length === 0) {
      this.loaded = false;
      this.API.fetchCounties().then(counties => {
        this.loaded = true;
        this.counties = counties.map(county => {
          return {
            hidden: false,
            matchWord: county.name.toLowerCase(),
            model: new CountyModel(county)
          }
        });
        this.filteredCounties = this.counties;
      });
    }
  },
  applyQueryAndProfile: function() {
    var q = this.query;
    this.counties.forEach(county => {
      var show = (!q || county.matchWord.indexOf(q) === 0)
        && this.communityProfile.isMatch(county.model);
      county.hidden = !show;
    });
  },
  filterCounties: function(query) {
    var q = query.trim().toLowerCase();
    if (q !== this.query) {
      this.query = q;
      this.applyQueryAndProfile();
    }
  },
  applyProfile: function(newProfile) {
    // This will set the active profile and actually filter the counties
    this.communityProfile = newProfile;
    this.applyQueryAndProfile();
  },
  getSearchDescription: function() {
    var count = this.counties.filter(county => !county.hidden).length;
    var descriptions = [
      this.communityProfile.populationFilter.getDescription(),
      this.communityProfile.registeredVoterFilter.getDescription(),
      this.communityProfile.eligibleVoterFilter.getDescription(),
      this.communityProfile.turnoutFilter.getDescription(),
      this.communityProfile.electionResultsFilter.getDescription(),
    ].filter(d => d);
    if (!this.query && descriptions.length === 0) {
      return `<b>All ${count} Counties</b>`;
    } else {
      var text = `<b>${count} Count${count === 1 ? 'y' : 'ies'}</b>`;
      if (this.query) {
        text = text + ` matching "${this.query}"`;
      }
      if (descriptions.length) {
        text = text + ` with ${descriptions.join(', ')}`
      }
      return text;
    }
  },
  aggregate: function() {
    var state = {
      fips: '13',
      name: 'Georgia',
      population: 0,
      area: 0,
      demographics: {
        race: {}
      },
      voters: {
        eligible: 0,
        registered: 0,
        byRace: {}
      },
      votes: {}
    }
    this.counties.forEach(countyModel => {
      if (countyModel.hidden) {
        return;
      }
      var county = countyModel.model
      state.population += county.population || 0
      state.area += county.area || 0
      Object.keys(county.demographics.race).forEach(race => {
        if (!state.demographics.race[race]) state.demographics.race[race] = 0
        state.demographics.race[race] += county.demographics.race[race] * county.population
      })
      state.voters.eligible += county.voters.eligible
      state.voters.registered += county.voters.registered
      Object.keys(county.voters.byRace).forEach(race => {
        if (!state.voters.byRace[race]) state.voters.byRace[race] = 0
        state.voters.byRace[race] += county.voters.byRace[race]
      })
      Object.keys(county.votes).forEach(contest => {
        if (!state.votes[contest]) state.votes[contest] = { total: 0, counts: {} }
        state.votes[contest].total += county.votes[contest].total
        Object.keys(county.votes[contest].counts).forEach(party => {
          if (!state.votes[contest].counts[party]) state.votes[contest].counts[party] = 0
          state.votes[contest].counts[party] += county.votes[contest].counts[party]
        })
      })
    })
    Object.keys(state.demographics.race).forEach(race => {
      state.demographics.race[race] /= state.population
    })
    console.log(`State demographics: ${JSON.stringify(state.demographics)}`)
    return new CountyModel(state)
  }
});

module.exports = SearchModel;
