<template>
  <div class="loader">
    <div class="preloader-wrapper big active">
      <div class="spinner-layer spinner-blue-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div><div class="gap-patch">
          <div class="circle"></div>
        </div><div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
    <div v-if="message" v-text="message" class="message blue-text"/>
  </div>
</template>

<script>
export default {
  props: ['message'],
  data() {
    return {};
  }
}
</script>

<style scoped>
.loader {
  text-align: center;
  padding: 8px 12px;
}
.loader > .message {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 12px;
}
</style>
