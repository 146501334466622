var CountyModel = function(data) {
  $.extend(this, data);
  this.cachedValues = {};
};

$.extend(CountyModel.prototype, {
  getPopulation: function(race) {
    return this.getPopulationRatio(race) * this.population;
  },
  getPopulationRatio: function(race) {
    if (!race) {
      return 1;
    } else if (race === 'other') {
      var total = 0;
      Object.keys(CountyModel.RACES).forEach(r => {
        var value = this.demographics.race[r] || 0;
        total += value;
      });
      return Math.max(0, 1 - total);
    } else {
      return this.demographics.race[race] || 0;
    }
  },
  getRegisteredVoters: function(race) {
    if (!race) {
      return this.voters.registered;
    } else if (race === 'other') {
      var other = this.voters.byRace.other || 0;
      Object.keys(this.voters.byRace).forEach(race => {
        if (!CountyModel.RACES[race] && race !== 'unknown') {
          other += this.voters.byRace[race];
        }
      });
      return other;
    } else {
      return this.voters.byRace[race];
    }
  },
  getNonRegisteredVoters: function(race) {
    return this.getPopulation(race) - this.getRegisteredVoters(race);
  },
  estimateUnknownRegisteredVoters: function(race) {
    return this.getPopulationRatio(race) * this.voters.byRace.unknown;
  },
  estimateEligibleVoters: function(race, adjustForUnknown) {
    var nonRegisteredVoters = this.getNonRegisteredVoters(race);
    if (race && adjustForUnknown) {
      nonRegisteredVoters -= this.estimateUnknownRegisteredVoters(race);
    }
    var shareOfNonRegisteredVoters = nonRegisteredVoters / this.getNonRegisteredVoters();
    var totalEligible = Math.max(0, this.voters.eligible - this.voters.registered);
    return shareOfNonRegisteredVoters * totalEligible;
  },
  getEstimatedEligibleVoterRanges: function() {
    var eligibility = this.voters.eligible / this.population;
    var ranges = [
      {
        key: 'overall',
        label: 'Overall',
        color: '#000',
        // min: this.voters.eligible - this.voters.registered,
        // max: this.population - this.voters.registered
        min: this.estimateEligibleVoters(),
        max: this.estimateEligibleVoters()
      }
    ];
    Object.keys(CountyModel.RACES).filter(race => race !== 'unknown').forEach(race => {
      var total = 0.9*this.getPopulation(race);
      var eligible = this.estimateEligibleVoters(race);
      var registered = this.getRegisteredVoters(race);
      var unknown = this.estimateUnknownRegisteredVoters(race);
      ranges.push({
        key: race,
        label: CountyModel.RACES[race].label,
        color: CountyModel.RACES[race].color,
        // min: Math.max(0, eligible - registered - unknown),
        // max: Math.max(0, total - registered)
        min: this.estimateEligibleVoters(race, true),
        max: this.estimateEligibleVoters(race)
      })
    });
    // Narrow the ranges
    // ranges.forEach(range => {
    //   var r = range.max - range.min;
    //   range.min += r / 4;
    //   range.max -= r / 4;
    // })
    return ranges;
  },
  getWhiteRegistrationPercentage: function() {
    var whitePopulation = this.population * this.demographics.race.white;
    var whiteVoters = this.voters.byRace.white;
    return whiteVoters / whitePopulation;
  },
  getMinorityRegistrationPercentage: function() {
    var minorityPopulation = this.population * (1 - this.demographics.race.white);
    var minorityVoters = this.voters.registered - this.voters.byRace.white - this.voters.byRace.unknown;
    return minorityVoters / minorityPopulation;
  },
  getMinorityRepresentationScore: function() {
    return this.getMinorityRegistrationPercentage() / this.getWhiteRegistrationPercentage();
  },
  getTrumpScore: function() {
    var trump = this.votes.president.counts.R / this.votes.president.total;
    var count = 0;
    var total = 0;
    for (var contest in this.votes) {
      if (contest !== 'president') {
        count++;
        total += this.votes[contest].counts.R / this.votes[contest].total;
      }
    }
    var avg = total / count;
    return trump / avg - 1;
  },

  getPopulationSegments: function(excludeUnknown) {
    var total = this.getPopulation();
    return Object.keys(CountyModel.RACES).filter(race => race !== 'unknown')
    .map(race => {
      var population = this.getPopulation(race);
      return {
        race: race,
        label: CountyModel.RACES[race].label,
        color: CountyModel.RACES[race].color,
        count: population,
        percent: population / total
      };
    });
  },
  getRegisteredVoterSegments: function() {
    return Object.keys(CountyModel.RACES)
    .map(race => {
      var population = this.getPopulation(race);
      var registered = this.getRegisteredVoters(race);
      return {
        race: race,
        label: CountyModel.RACES[race].label,
        color: CountyModel.RACES[race].color,
        count: registered,
        percent: registered / population,
        hidePercent: race === 'unknown'
      };
    });
  },
  getEligibleVoterSegments: function() {
    return Object.keys(CountyModel.RACES).filter(race => race !== 'unknown')
    .map(race => {
      var population = this.getPopulation(race);
      var eligible = this.estimateEligibleVoters(race, true);
      return {
        race: race,
        label: CountyModel.RACES[race].label,
        color: CountyModel.RACES[race].color,
        count: eligible,
        percent: eligible / population
      };
    });

    var populationSegments = this.getPopulationSegments();
    var registrationSegments = this.getRegisteredVoterSegments();

    // Calvulate eligible segments by subtracting registered from population
    var eligibleSegments = [];
    for (var i = 0; i < populationSegments.length; i++) {
      var segment = {
        race: populationSegments[i].race,
        label: populationSegments[i].label,
        color: populationSegments[i].color,
        count: populationSegments[i].count - registrationSegments[i].count
      }
      eligibleSegments.push(segment);
    }

    // Distribute the unknowns proportionately to population demographics
    var unknown = eligibleSegments[eligibleSegments.length-1].count;
    eligibleSegments.forEach((segment, i) => {
      if (i === eligibleSegments.length-1) {
        segment.count = segment.percent = 0;
      } else {
        segment.count += unknown * populationSegments[i].percent;
        segment.count = Math.max(0, segment.count);
      }
    });
    var total = 0;
    eligibleSegments.forEach(segment => total += segment.count);
    eligibleSegments.forEach(segment => segment.percent = segment.count / this.estimateUnknownRegisteredVoters(segment.race));

    return eligibleSegments.slice(0, eligibleSegments.length-1);
  },
  getTurnoutSegments: function() {
    return [
      {
        label: 'Voted',
        color: '#1565c0',
        count: this.votes.president.total
      },
      {
        label: 'Did Not Vote',
        color: '#42a5f5',
        count: this.voters.registered - this.votes.president.total
      },
      {
        label: 'Not Registered',
        color: '#90caf9',
        count: this.estimateEligibleVoters() - this.voters.registered
      },
      {
        label: 'Under 18',
        color: '#aaa',
        count: this.getPopulation() - this.estimateEligibleVoters()
      }
    ];
  },
  forEachContest: function(/* { include: [contestName], exclude: [contestName] } */ filter,
    /* fn(contestName, contest) */ fn) {
    Object.keys(this.votes).forEach(contestName => {
      if (filter &&
          (filter.include && filter.include.indexOf(contestName) < 0) ||
          (filter.exclude && filter.exclude.indexOf(contestName) >= 0)) {
        return;
      } else {
        fn(contestName, this.votes[contestName]);
      }
    })
  },
  getPartisanLean: function(filter) {
    var total = 0;
    var count = 0;
    this.forEachContest(filter, (contestName, contest) => {
      if (contest.counts.R && contest.counts.D) {
        count++;
        total += (contest.counts.R - contest.counts.D) / contest.total;
      }
    });
    return total / count;
  },
  getContestSegmentGroups: function(filter) {
    var segments = [];
    this.forEachContest(filter, (contestName, contest) => {
      segments.push({
        contest: contestName,
        segments: this.getContestSegmentsForGroups(contest)
      });
    });
    return segments;
  },
  getContestSegmentsForGroups: function(contest, multiplier) {
    var segments = [];
    for (var party in contest.counts) {
      var count = contest.counts[party];
      segments.push({
        label: party,
        color: CountyModel.PARTY_COLORS[party] || '#eee',
        count,
        percent: count / contest.total
      });
    }
    return segments;
  },
  getRegistrationSegmentGroups: function() {
    return Object.keys(CountyModel.RACES).map(race => {
      return race === 'unknown' ? null : {
        race,
        segments: this.getRegistrationSegmentsForGroups(race)
      };
    }).filter(segment => segment);
  },
  getRegistrationSegmentsForGroups: function(race) {
    var estimatedEligible = this.estimateEligibleVoters(race)
    return [
      {
        label: 'Registered',
        color: CountyModel.RACES[race].color,
        count: this.voters.byRace[race]
      },
      {
        label: 'Not Registered',
        color: CountyModel.RACES[race].color+'22',
        count: Math.max(0, estimatedEligible - this.voters.byRace[race])
      }
    ];
  }
});

function mapFunction(key, fn) {
  CountyModel.prototype[key] = function() {
    var args = [];
    for (var i =  0; i < arguments.length; i++) {
      if (arguments[i] !== undefined) {
        args[i] = arguments[i];
      }
    }
    var argsKey = JSON.stringify(args);
    var cacheKey = `${key}(${argsKey})`;
    if (!this.cachedValues[cacheKey]) {
      this.cachedValues[cacheKey] = fn.apply(this, args);
    }
    return this.cachedValues[cacheKey];
  }
}

for (var key in CountyModel.prototype) {
  var fn = CountyModel.prototype[key];
  if (typeof fn === 'function' && key.match(/^get/)) {
    mapFunction(key, fn);
  }
}

$.extend(CountyModel, {
  RACES: {
    white: {
      label: 'White',
      // color: '#4527a0' // deep-purple
      color: '#283593' // indigo
      // color: '#3949ab' // indigo light
    },
    black: {
      label: 'Black',
      // color: '#00838f' // cyan
      // color: '#00695c' // teal
      color: '#ff8f00' // amber
      // color: '#ffb300' // amber
    },
    hispanic: {
      label: 'Hispanic',
      // color: '#f9a825' //yellow
      // color: '#2e7d32', // green
      // color: '#9e9d24' // lime
      // color: '#00897b' // teal
      color: '#00695c' // teal
    },
    other: {
      label: 'Other',
      color: '#d84315' // deep-orange
      // color: '#f4511e'
    },
    unknown: {
      label: 'Unknown',
      color: '#aaa'
    }
    //   // asian: '#ff9800',
    //   // americanIndian: '#9c27b0'
  },
  PARTY_COLORS: {
    R: '#F44336',
    D: '#2196F3',
    I: '#9c27b0',
    G: '#4CAF50',
    L: '#ff9800'
  }
});


module.exports = CountyModel;
