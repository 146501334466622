<template>
  <div :class="`navbar-fixed ${profileForEdit ? 'filters-open' : ''}`">
    <nav>
      <div class="nav-wrapper">
        <router-link :to="{ name: 'home' }" class="logo left">
          <img src="/favicon.png" />
        </router-link>
        <ul class="right">
          <li v-if="showMapLink">
            <router-link :to="{name:'map'}">
              <span class="hide-on-med-and-up"><i class="material-icons">map</i></span>
              <span class="hide-on-small-only">Map</span>
            </router-link>
          </li>
          <li v-if="showListLink">
            <router-link :to="{name:'list'}">
              <span class="hide-on-med-and-up"><i class="material-icons">list</i></span>
              <span class="hide-on-small-only">List</span>
            </router-link>
          </li>
          <li>
            <a href="#" @click.prevent="toggleProfileEditor()">
              <span class="hide-on-med-and-up"><i class="material-icons">tune</i></span>
              <span class="hide-on-small-only">Filters</span>
            </a>
          </li>
        </ul>
        <div class="input-holder">
          <input type="search" v-model="query" placeholder="Search Georgia Counties" />
          <a href="#" @click.prevent="query = ''" class="clear-btn btn-flat" v-if="query">
            <i class="material-icons">close</i>
          </a>
        </div>
      </div>
    </nav>
    <div class="filter-sidenav">
      <div class="filters-header clearfix">
        <a href="#" class="right btn" @click.prevent="applyProfile()">Apply</a>
        <a href="#" class="right btn-flat" @click.prevent="cancelProfile()">Cancel</a>
        <div class="filters-header-title">
          Filters
        </div>
      </div>
      <FiltersForm :profile="profileForEdit" />
    </div>
  </div>
</template>

<script>
import FiltersForm from './forms/FiltersForm.vue';
export default {
  props: ['showMapLink','showListLink'],
  components: {FiltersForm},
  data() {
    return {
      query: '',
      countySearchTask: 0,
      profileForEdit: null
    };
  },
  watch: {
    query: function() {
      clearTimeout(this.countySearchTask);
      this.countySearchTask = setTimeout(() => {
        this.model.filterCounties(this.query);
      }, 10);
    }
  },
  methods: {
    toggleProfileEditor: function() {
      if (this.profileForEdit) {
        this.cancelProfile();
      } else {
        this.editProfile();
      }
    },
    editProfile: function() {
      this.profileForEdit = this.model.communityProfile.clone();
    },
    applyProfile: function() {
      this.model.applyProfile(this.profileForEdit);
      this.profileForEdit = null;
    },
    cancelProfile: function() {
      this.profileForEdit = null;
    }
  }
}
</script>

<style scoped>
nav {
  background-color: #fff;
}
nav .nav-wrapper .input-holder {
  position: relative;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
}
nav .nav-wrapper .input-holder .clear-btn {
  position: absolute;
  right: 0px;
  top: 0px;
}
nav ul a {
  color: #333;
}
.logo img {
  height: 40px;
  margin: 12px;
}
@media only screen and (max-width: 600px) {
  .logo img {
    margin: 8px;
  }
}
nav .nav-wrapper .input-holder input {
  padding: 0px 12px;
  width: calc(100% - 26px);
  border: 1px solid #ccc;
  border-radius: 4px !important;
}
.filter-sidenav {
  position: fixed;
  right: -500px;
  width: 100%;
  max-width: 500px;
  overflow: auto;
  top: 64px;
  height: calc(100% - 64px);
  background-color: #fff;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  box-shadow: -3px 3px 3px rgba(0,0,0,0.2);
  transition: right 250ms;
}
.filters-open .filter-sidenav {
  right: 0px;
}
.filter-sidenav .filters-header {
  padding: 8px 16px;
  line-height: 36px;
  border-bottom: 1px solid #ccc;
}
.filters-header-title {
  font-size: 22px;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .filter-sidenav {
    top: 56px;
    height: calc(100% - 56px);
  }
}
</style>
