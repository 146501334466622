var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "clearfix " + (_vm.inline ? "inline" : "") },
    [
      _vm.label ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.options, function(option) {
        return _c("p", { key: option.value }, [
          _c("label", [
            (_vm.multi ? "checkbox" : "radio") === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data[_vm.field],
                      expression: "data[field]"
                    }
                  ],
                  attrs: { name: _vm.group, type: "checkbox" },
                  domProps: {
                    value: option.value,
                    checked: Array.isArray(_vm.data[_vm.field])
                      ? _vm._i(_vm.data[_vm.field], option.value) > -1
                      : _vm.data[_vm.field]
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.data[_vm.field],
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = option.value,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.data, _vm.field, $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.data,
                              _vm.field,
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.data, _vm.field, $$c)
                      }
                    }
                  }
                })
              : (_vm.multi ? "checkbox" : "radio") === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data[_vm.field],
                      expression: "data[field]"
                    }
                  ],
                  attrs: { name: _vm.group, type: "radio" },
                  domProps: {
                    value: option.value,
                    checked: _vm._q(_vm.data[_vm.field], option.value)
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.data, _vm.field, option.value)
                    }
                  }
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data[_vm.field],
                      expression: "data[field]"
                    }
                  ],
                  attrs: {
                    name: _vm.group,
                    type: _vm.multi ? "checkbox" : "radio"
                  },
                  domProps: { value: option.value, value: _vm.data[_vm.field] },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.data, _vm.field, $event.target.value)
                    }
                  }
                }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(option.label))])
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }