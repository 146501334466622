<template>
  <div class="filter-field">
    <h6>{{ label }}</h6>
    <div class="value-range">
      <input type="number" v-model="filter.min" placeholder="Min" />
      <span v-if="filter.type === 'percent'" class="percent-label"> % </span>
      <span> - </span>
      <input type="number" v-model="filter.max" placeholder="Max" />
      <span v-if="filter.type === 'percent'" class="percent-label"> % </span>
      <span v-if="filter.contestOptions"> % margin </span>
    </div>
    <div class="race-selection" v-if="filter.raceOptions">
      <SelectionView :inline="true" :multi="true"
        :options="filter.raceOptions" :data="filter"
        field="race" />
    </div>
    <div class="contest-selection" v-if="filter.contestOptions">
      <SelectionView :inline="true" :options="filter.contestOptions"
        :data="filter" field="winner" />
    </div>
  </div>
</template>

<script>
import SelectionView from './SelectionView.vue';
export default {
  components: {SelectionView},
  props: ['label', 'filter'],
  data() {
    return {};
  }
}
</script>

<style scoped>
.filter-field {
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
}
h6 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 12px;
  margin-top: 4px;
}
.btn-flat {
  border-bottom: 1px solid #ccc;
}
input[type=number] {
  width: auto;
  display: inline-block;
  height: 32px;
  padding: 0px 8px;
  border: 1px solid #ccc;
}
input[type=number]+.percent-label {
  position: relative;
  margin-left: -28px;
  margin-right: 12px;
  /* transition: margin-left 100ms, margin-right 100ms; */
}
input[type=number]:hover+.percent-label,
input[type=number]:focus+.percent-label {
  margin-left: -48px;
  margin-right: 32px;
}
select {
  width: auto;
  display: inline-block;
}
.race-selection, .contest-selection {
  margin-top: -8px;
}
</style>
