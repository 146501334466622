<template>
  <div class="partisan-lean-cell">
    <div class="stack-title">
      <div class="right lean-value" :style="{color:leanColor}" v-text="formatLean(lean, 'R', 'D')"/>
      <div class="responsive-text" v-text="label"/>
    </div>
    <BarStack class="small" v-for="group in segmentGroups"
      :key="group.contest"
      :segments="group.segments" />
  </div>
</template>

<script>
import BarStack from '../BarStack.vue';
export default {
  components: {BarStack},
  props: ['county','label','include','exclude','max'],
  data() {
    return {};
  },
  computed: {
    lean: function() {
      return this.county.getPartisanLean({include:this.include,exclude:this.exclude});
    },
    leanColor: function() {
      // red = 244,67,53
      // blue = 34, 150, 243
      // purple = 156, 38, 176
      var factor = Math.min(1, Math.max(-1, 10 * this.lean));
      if (factor > 0) {
        var r = 156 + factor * 88;
        var g = 38 + factor * 29;
        var b = 176 - factor * 123;
        return `rgb(${r},${g},${b})`;
      } else {
        var r = 156 + factor * 122;
        var g = 38 - factor * 112;
        var b = 176 - factor * 67;
        return `rgb(${r},${g},${b})`;
      }
    },
    segmentGroups: function() {
      var groups = this.county.getContestSegmentGroups({include:this.include,exclude:this.exclude})
      if (this.max) return groups.slice(0, this.max);
      else return groups;
    }
  }
}
</script>

<style>
.partisan-lean-cell .bar-stack.small {
  height: 8px;
}
.lean-value {
  font-weight: 800;
}
</style>
