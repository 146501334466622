const { formatNumber, formatPercent } = require('../utils.js');

function sum(array, fn) {
  if (array.length) {
    var sum = 0;
    array.forEach(item => sum += fn(item));
    return sum;
  } else {
    return fn();
  }
}

//
// Voter Filters
//

function createVoterRacialSumFunction(fn) {
  return (county, profile) => {
    return sum(profile.race, race => fn(county, race));
  }
}

const VOTER_SCOPES = {
  all: {
    label: 'Everyone',
    countMatches:
      createVoterRacialSumFunction((count, race) => county.getPopulation(race))
  },
  registered: {
    label: 'Registered Voters',
    countMatches:
      createVoterRacialSumFunction((county, race) => county.getRegisteredVoters(race))
  },
  eligible: {
    label: 'Eligible Voters',
    countMatches:
      createVoterRacialSumFunction((county, race) => county.estimateEligibleVoters(race))
  },
  voted: {
    label: 'Voted in Nov. 3 Election',
    countMatches: (county, profile) => {
      if (profile.party === 'R') {
        return county.votes.president.counts.R;
      } else if (profile.party === 'D') {
        return county.votes.president.counts.D;
      } else {
        county.votes.president.total;
      }
    }
  }
};

function VoterProfile() {
  this.scope = 'all';
  this.race = [];
  this.party = 'either';
}
$.extend(VoterProfile.prototype, {
  countMatches: county => {
    return VOTER_SCOPES[this.scope].countMatches(county, this);
  }
});

//
// Community Filters
//
function defineFilterClass(defaultState, calculateValue, options) {
  options = options || {};
  var klass = function(state) {
    this.min = null;
    this.max = null;
    this.enabled = false;
    this.type = 'count';
    $.extend(this, defaultState, state);
  }
  klass.prototype.isMatch = function(county) {
    var value = this.calculateValue(county);
    if (this.type === 'percent') value *= 100;
    if (this.min && value < this.min) {
      return false;
    }
    if (this.max && value > this.max) {
      return false;
    }
    return true;
  };
  klass.prototype.calculateValue = calculateValue;
  if (options.raceOptions) {
    klass.prototype.raceOptions = [
      {value:'white',label:'White'},
      {value:'black',label:'Black'},
      {value:'hispanic',label:'Hispanic'},
      {value:'other',label:'Other'}
    ];
  }
  if (options.contestOptions) {
    klass.prototype.contestOptions = [
      {value:'D',label:'Biden'},
      {value:'R',label:'Trump'}
    ];
  }
  if (options.getDescription) {
    klass.prototype._getDescription = options.getDescription;
  } else {
    klass.prototype._getDescription = function() { return null; }
  }
  klass.prototype.getDescription = function() {
    if (this.min || this.max) {
      return this._getDescription();
    } else {
      return null;
    }
  }
  klass.prototype.getValueRangeText = function() {
    var minText = this.type === 'percent' ? formatPercent(this.min / 100) : formatNumber(this.min);
    var maxText = this.type === 'percent' ? formatPercent(this.max / 100) : formatNumber(this.max);
    if (this.min && this.max) return `between ${minText} and ${maxText}`;
    else if (this.min) return `over ${minText}`;
    else if (this.max) return `under ${maxText}`;
  }

  return klass;
}

var PopulationFilter = defineFilterClass(
  {race:[]},
  function(county) {
    return sum(this.race, race => county.getPopulation(race));
  },
  {
    getDescription: function() {
      return `population ${this.getValueRangeText()}`;
    }
  }
);

var TurnoutFilter = defineFilterClass(
  {type:'percent'},
  function(county) {
    return county.votes.president.total / county.voters.registered;
  },
  {
    getDescription: function() {
      return `turnout ${this.getValueRangeText()}`;
    }
  }
);

var ElectionResultsFilter = defineFilterClass(
  {winner:'D',type:'percent'},
  function(county) {
    var contest = county.votes.president;
    var dPref = (contest.counts.D - contest.counts.R) / contest.total;
    if (this.winner === 'D') return dPref;
    else return -dPref;
  },
  {
    contestOptions: true,
    getDescription: function() {
      return `voted for ${this.winner === 'D' ? 'Biden' : 'Trump'} by a margin ${this.getValueRangeText()}`;
    }
  }
);

var VoterFilter = defineFilterClass(
  {race:[],type:'percent',scope:'all'},
  function(county) {
    var numerator = VOTER_SCOPES[this.scope].countMatches(county, {race:this.race});
    var denominator = 1;
    if (this.type === 'percent') {
      denominator = sum(this.race, race => county.getPopulation(race))
    } else if (this.type === 'percent_of_white_voters') {
      denominator = VOTER_SCOPES[this.scope].countMatches(county, {race:['white']});
    } else if (this.type === 'percent_of_voters') {
      denominator = VOTER_SCOPES[this.scope].countMatches(county, {race:[]});
    }
    return numerator / denominator;
  },
  {
    raceOptions: true,
    getDescription: function() {
      var text = `${this.scope === 'registered' ? 'registered' : 'eligible'}`;
      if (this.race.length === 0 || this.race.length === 4) {
        text = text + ' voters';
      } else {
        text = text + ` ${this.race.map(r => r[0].toUpperCase() + r.substr(1)).join(', ')} voters`;
      }
      return `${text} ${this.getValueRangeText()}`;
    }
  }
);

function CommunityProfile() {
  this.populationFilter = new PopulationFilter();
  this.registeredVoterFilter = new VoterFilter({scope:'registered'});
  this.eligibleVoterFilter = new VoterFilter({scope:'eligible'})
  this.turnoutFilter = new TurnoutFilter();
  this.electionResultsFilter = new ElectionResultsFilter();
}
CommunityProfile.prototype.isMatch = function(county) {
  return this.populationFilter.isMatch(county)
    && this.registeredVoterFilter.isMatch(county)
    && this.eligibleVoterFilter.isMatch(county)
    && this.turnoutFilter.isMatch(county)
    && this.electionResultsFilter.isMatch(county);
}
CommunityProfile.prototype.clone = function() {
  var copy = new CommunityProfile();
  $.extend(copy.populationFilter, this.populationFilter);
  $.extend(copy.registeredVoterFilter, this.registeredVoterFilter);
  $.extend(copy.eligibleVoterFilter, this.eligibleVoterFilter);
  $.extend(copy.turnoutFilter, this.turnoutFilter);
  $.extend(copy.electionResultsFilter, this.electionResultsFilter);
  return copy;
}

function CampaignModel() {
  this.name = '';
  this.voterProfile = new VoterProfile();
  this.communityProfile = new CommunityProfile();
  this.result = null;
}
CampaignModel.prototype.runModel = function(counties) {
  var result = {
    counties: [],
    totalsByCounty: {},
    total: 0
  };
  counties.forEach(county => {
    if (this.communityProfile.isMatch(county)) {
      var count = this.voterProfile.countMatches(county);
      this.counties.push(county);
      this.totalsByCounty[county.fips] = count;
      this.total += count;
    }
  });
  this.result = result;
}

module.exports = {
  CampaignModel,
  CommunityProfile,
  VoterProfile
};
